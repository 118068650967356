import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

import DefaultImg from "../../../../assets/images/no_image.png";
import { formatMoney } from "../../../../helpers/formatters";
import { useNavigate } from "react-router-dom";

import "./style.scss";

const propTypes = {
  services: PropTypes.array,
  loadMore: PropTypes.func,
  totalPage: PropTypes.number,
  currentPage: PropTypes.number,
  category: PropTypes.number,
  stateButton: PropTypes.string,
  error: PropTypes.bool,
  getData: PropTypes.func,
};

const Items = ({
  services,
  loadMore,
  totalPage,
  currentPage,
  stateButton,
  error,
  getData,
}) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const [lang, setLang] = useState("en");

  const goToDetail = (id) => {
    navigate(
      `/product?id=${id}&lang=${lang}${
        stateButton === "request" ? "&onRequest=true" : ""
      }`
    );
  };
  const pageNumberArray = Array.from(
    { length: totalPage },
    (_, index) => index + 1
  );

  useEffect(() => {
    setLang(i18n.language);
  }, [i18n.language]);

  if (error) {
    return (
      <div className="flex flex-col justify-center items-center gap-6">
        <h4 className="text-xl">Something went wrong</h4>
        <button
          className="py-2 btn btn-secondary !rounded-none"
          onClick={() => getData()}
        >
          Try Again
        </button>
      </div>
    );
  }

  return (
    <>
      <section>
        {services && services.length > 0 ? (
          <div className="grid lg:grid-cols-3 gap-8 lg:gap-3 mb-10">
            {services.map((service) => {
              return (
                <div
                  className="border border-[#bab8b8] bg-white cursor-pointer"
                  key={service.Id}
                  onClick={() => goToDetail(service.Id)}
                >
                  <div
                    className="image"
                    onClick={(e) => {
                      e.preventDefault();
                      goToDetail(service.Id);
                    }}
                  >
                    <img
                      src={
                        service.Images !== null
                          ? service.Images[0].Url
                          : DefaultImg
                      }
                      className=" h-[220px] lg:h-[180px] w-full object-cover"
                      alt={service.Name}
                    />
                  </div>

                  <div className="p-3 pt-1">
                    <div
                      className="font-bold mb-2 text-center line-clamp-2"
                      onClick={(e) => {
                        e.preventDefault();
                        goToDetail(service.Id);
                      }}
                      title={service?.Name}
                    >
                      {service.Name}
                    </div>
                    {service.Availability.Calendar.LowestRate && (
                      <div className="price !mb-3 bg-[#bcb9b9] rounded-3xl text-[#303030] px-3 py-1 text-sm inline-flex">
                        {lang === "jp"
                          ? `¥${formatMoney(
                              service.Availability.Calendar.LowestRate
                            )} ~`
                          : `${t("from")} ¥${formatMoney(
                              service.Availability.Calendar.LowestRate
                            )}`}
                      </div>
                    )}
                    <div
                      className="desc line-clamp-3 !mb-3"
                      dangerouslySetInnerHTML={{
                        __html: service.ShortDescription
                          ? service.ShortDescription
                          : service.LongDescription,
                      }}
                    ></div>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <h3 className="text-center">{t("not_found")}</h3>
        )}
      </section>
      <div className="flex justify-center items-center mb-3 gap-2 flex-wrap">
        {pageNumberArray.map((element) => {
          return (
            <button
              className={`py-2 btn btn-secondary !rounded-none ${
                currentPage === element ? "!bg-twprimary !border-twprimary" : ""
              }`}
              onClick={() => loadMore(element)}
              key={element}
            >
              {element}
            </button>
          );
        })}
        {/* {totalPage > 1 && totalPage > currentPage && state === "quick" && (
          <button
            className="py-2 btn btn-secondary !rounded-none"
            onClick={() => loadMore()}
          >
            {t("load_more")}
          </button>
        )} */}
      </div>
    </>
  );
};

Items.propTypes = propTypes;

export default Items;
