import { SignJWT } from "jose";

export async function generateToken(id) {
  // Example payload, you can replace it with your own data

  const payload = {
    data: id,
  };

  // Example secret key, replace it with your own secret key
  const secretKey = "N11cl34p1V3";
  const iat = Math.floor(Date.now() / 1000);
  const exp = iat + 60 * 60; // one hour

  // Generate the token
  return await new SignJWT({ ...payload })
    .setProtectedHeader({ alg: "HS256", typ: "JWT" })
    .setExpirationTime(exp)
    .setIssuedAt(iat)
    .setNotBefore(iat)
    .sign(new TextEncoder().encode(secretKey));
}
