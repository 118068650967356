/* eslint-disable react/prop-types */
import React, { useState } from "react";
import logo from "../../assets/images/niicle_logo.png";

import "./style.scss";

const Navigation = () => {
  const [open, setOpen] = useState(false);
  return (
    <header>
      <div id="sub_header">
        <a href="/">
          <div className="sub_logo flex flex-col justify-center items-center p-3.5 border-b-2 border-twprimary pb-0 lg:!pb-3.5 lg:border-0">
            <p className="sub_txt text-sm mb-0 opacity-90">
              にいがた観光ファンクラブ
            </p>
            <img src={logo} className="w-[225px] lg:w-[175px]" alt="" />
          </div>
        </a>
      </div>
      <div className="nav_menu bg-[#DCDCDC] hidden lg:block">
        {/* <input type="checkbox" id="menu_check" />
        <label className="menu_button">
          <span className="top"></span>
          <span className="center"></span>
          <span className="bottom"></span>
        </label>
        <p className="return">
          <a href="#"></a>
        </p> */}
        <ul className="menu flex justify-around p-7 h-[90px] font-bold items-center text-sm">
          <li>
            <a
              href="https://www.niigatafan-niicle.com/2022-cp-a-w/"
              target="_blank"
              rel="noreferrer"
            >
              キャンペーン情報
            </a>
          </li>
          <li>
            <a href="https://www.niigatafan-niicle.com/companies">
              Niicle協力施設一覧
            </a>
          </li>
          <li className="alc">
            <a href="https://www.niigatafan-niicle.com/specialties/">
              特産品一覧
            </a>
          </li>
          <li className="alc">
            <a href="https://www.niigatafan-niicle.com/feature/">特集コラム</a>
          </li>
          <li className="alc text-center">
            <a href="https://niigata-kankou.or.jp/" target="blank">
              <span className="fs07 text-[10px] text-center">
                <svg
                  className="svg-inline--fa fa-caret-down fa-w-10 mr5"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fa"
                  data-icon="caret-down"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                  ></path>
                </svg>
                オススメ観光情報はこちら
              </span>
              <br />
              【公式】にいがた観光ナビ
            </a>
          </li>
          <li className="">
            <a href="https://www.niigatafan-niicle.com/signup/">会員登録</a>
          </li>
          <li className="">
            <a href="https://www.niigatafan-niicle.com/mypage/">ログイン</a>
          </li>
        </ul>
      </div>
      <div
        className={`h-[60px] w-[60px] fixed right-0 top-0 flex flex-col bg-[#e4c963] 
        opacity-70 z-50 lg:hidden menu_button justify-center items-center transition ${
          open ? "active" : ""
        }`}
        onClick={() => setOpen(!open)}
      >
        <span className="top"></span>
        <span className="center"></span>
        <span className="bottom"></span>
      </div>
      <ul
        className={`menu w-full h-full bg-white fixed z-40 top-0 
      left-0 pt-[70px] transition text-center ${
        open ? "translate-x-0" : "translate-x-full"
      }`}
      >
        <li className="!py-5 border-b border-[#aaa] font-bold">
          <a
            href="https://www.niigatafan-niicle.com/2022-cp-a-w/"
            target="_blank"
            rel="noreferrer"
          >
            キャンペーン情報
          </a>
        </li>
        <li className="!py-5 border-b border-[#aaa] font-bold">
          <a href="https://www.niigatafan-niicle.com/companies">
            Niicle協力施設一覧
          </a>
        </li>
        <li className="!py-5 border-b border-[#aaa] font-bold">
          <a href="https://www.niigatafan-niicle.com/specialties/">
            特産品一覧
          </a>
        </li>
        <li className="!py-5 border-b border-[#aaa] font-bold">
          <a href="https://www.niigatafan-niicle.com/feature/">特集コラム</a>
        </li>
        <li className="!py-5 border-b border-[#aaa] font-bold">
          <a href="https://niigata-kankou.or.jp/" target="blank">
            <span className="fs07 text-[10px] text-center">
              <svg
                className="svg-inline--fa fa-caret-down fa-w-10 mr5"
                aria-hidden="true"
                focusable="false"
                data-prefix="fa"
                data-icon="caret-down"
                role="img"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 320 512"
                data-fa-i2svg=""
              >
                <path
                  fill="currentColor"
                  d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                ></path>
              </svg>
              オススメ観光情報はこちら
            </span>
            <br />
            【公式】にいがた観光ナビ
          </a>
        </li>
        <li className="!py-5 border-b border-[#aaa] font-bold">
          <a href="https://www.niigatafan-niicle.com/signup/">会員登録</a>
        </li>
        <li className="!py-5 border-b border-[#aaa] font-bold">
          <a href="https://www.niigatafan-niicle.com/mypage/">ログイン</a>
        </li>
      </ul>
    </header>
  );
};

export default Navigation;
