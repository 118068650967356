import React from "react";
import PropTypes from "prop-types";

import "./style.scss";

const Footer = () => {
  return (
    <div id="footer" className="bg-[#222] text-white">
      <article className="footer-nav ">
        <ul className="footer-nav-menu alc flex flex-col lg:flex-row justify-center items-center !gap-0 lg:!gap-10 font-bold lg:h-[80px]">
          <li className="footer-nav-list">
            <a
              href="https://87ea7b5e.viewer.kintoneapp.com/public/00000f088bf7ef3d29b41bab53f268c892b789e16b94bef7a5571562dbe703a4"
              target="_blank"
              rel="noreferrer"
            >
              キャンペーン情報
            </a>
          </li>
          <li className="footer-nav-list">
            <a href="https://www.niigatafan-niicle.com/companies/">
              Niicle協力施設一覧
            </a>
          </li>
          <li className="footer-nav-list alc">
            <a href="https://www.niigatafan-niicle.com/specialties/">
              <span className="">特産品一覧</span>
            </a>
          </li>
          <li className="footer-nav-list alc">
            <a href="https://www.niigatafan-niicle.com/feature/">
              <span className="">特集コラム</span>
            </a>
          </li>
          <li className="footer-nav-list alc text-center">
            <a href="https://niigata-kankou.or.jp/" target="blank">
              <span className="fs07 text-[11.2px]">
                <svg
                  className="svg-inline--fa fa-caret-down fa-w-10 mr5"
                  aria-hidden="true"
                  focusable="false"
                  data-prefix="fa"
                  data-icon="caret-down"
                  role="img"
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 320 512"
                  data-fa-i2svg=""
                >
                  <path
                    fill="currentColor"
                    d="M31.3 192h257.3c17.8 0 26.7 21.5 14.1 34.1L174.1 354.8c-7.8 7.8-20.5 7.8-28.3 0L17.2 226.1C4.6 213.5 13.5 192 31.3 192z"
                  ></path>
                </svg>
                オススメ観光情報はこちら
              </span>
              <br />
              【公式】にいがた観光ナビ
            </a>
          </li>
          <li className="footer-nav-list ">
            <a href="https://www.niigatafan-niicle.com/contact/">
              お問い合わせ
            </a>
          </li>
        </ul>
      </article>

      <p id="copyright" className="text-[8px] text-center p-2">
        <span>© 新潟県観光ファンづくり推進協議会</span>
      </p>
    </div>
  );
};

Footer.propTypes = {
  language: PropTypes.string,
  changeLanguage: PropTypes.func,
};

export default Footer;
