import axios from "axios";
import { generateToken } from "./jwt";
import { apiEndpoint } from "./endpoints";

export async function decryptId(token) {
  try {
    const res = await axios.get(
      `https://niicle.codeomnia.com/api/?token=${token}`
    );
    const id = res?.data?.decrypted_token;
    if (id) {
      const data = await callJWT(id);
      return data;
    }
  } catch (error) {
    console.log(error);
    return "error decrypt";
  }
}

async function callJWT(id) {
  try {
    const jwtToken = await generateToken(id);
    const res = await axios.get(`${apiEndpoint}/obx-transaction`, {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
      },
    });
    return res?.data;
  } catch (error) {
    console.error("Error calling JWT:", error);
    return null;
  }
}
