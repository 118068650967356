import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { isEmpty } from "lodash";
import { useSearchParams } from "react-router-dom";
import moment from "moment";
import { decryptId } from "../../helpers/decryptId";

const TransactionHistory = () => {
  const { t } = useTranslation();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [searchParams] = useSearchParams();
  // const [sort, setSort] = useState("asc");
  useEffect(() => {
    let token = sessionStorage.getItem("token");
    if (!token) {
      token = searchParams.get("token");
      sessionStorage.setItem("token", token);
    }
    setLoading(true);

    if (token) {
      const fetchData = async () => {
        const result = await decryptId(token);
        result?.sort(
          (a, b) =>
            new Date(b.Products?.Product?._attributes?.CommencingDate) -
            new Date(a.Products?.Product?._attributes?.CommencingDate)
        );

        setData(result);
        if (!result) {
          setData([]);
        }
        setLoading(false);
      };

      fetchData();
    }
  }, []);

  const sortData = (val) => {
    debugger; //eslint-disable-line
    if (val === "desc") {
      const sorted = data.sort(
        (a, b) =>
          new Date(a.Products?.Product?._attributes?.CommencingDate) -
          new Date(b.Products?.Product?._attributes?.CommencingDate)
      );
      setData([...sorted]);
    } else {
      const sorted = data.sort(
        (a, b) =>
          new Date(b.Products?.Product?._attributes?.CommencingDate) -
          new Date(a.Products?.Product?._attributes?.CommencingDate)
      );
      setData([...sorted]);
    }
  };
  useEffect(() => {
    if (data !== null) {
      setLoading(false);
    }
  }, [data]);

  return (
    <main className="container py-16">
      <h1 className="text-3xl font-bold mb-6">{t("transaction_history")}</h1>
      {!isEmpty(data) && !loading ? (
        <div className="flex flex-col lg:flex-row gap-10">
          <div className="w-full lg:w-1/3 ">
            <div className="grid gap-4 lg:sticky lg:top-4">
              <h4 className="font-bold text-xl ">{t("customer_details")}</h4>
              <div className="grid grid-cols-2 gap-4 ">
                <div>
                  <h5>{t("first_name")}:</h5>
                  <p className="font-bold text-lg mt-1">
                    {data[0]?.Customer?.Name?._attributes?.FirstName}
                  </p>
                </div>
                <div>
                  <h5>{t("last_name")}:</h5>
                  <p className="font-bold text-lg mt-1">
                    {data[0]?.Customer?.Name?._attributes?.LastName}
                  </p>
                </div>
              </div>

              <div>
                <h5>{t("email")}:</h5>
                <p className="font-bold text-lg mt-1">
                  {data[0]?.Customer?.Email?._attributes?.EmailAddress}
                </p>
              </div>
              <div className="grid lg:grid-cols-2 gap-4">
                <div>
                  <h5>{t("phone")}:</h5>
                  <p className="font-bold text-lg mt-1">
                    {data[0]?.Customer?.HomePhone?._attributes?.Number}
                  </p>
                </div>
                <div>
                  <h5>{t("address")}:</h5>
                  <p className="font-bold text-lg mt-1">
                    {data[0]?.Customer?.Address?._attributes?.PostCode}{" "}
                    {data[0]?.Customer?.Address?._attributes?.State}{" "}
                    {data[0]?.Customer?.Address?._attributes?.City}{" "}
                    {data[0]?.Customer?.Address?._attributes?.Line2}{" "}
                    {data[0]?.Customer?.Address?._attributes?.Line1}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:w-2/3">
            <div className="flex justify-between">
              <h4 className="mb-4 font-bold text-xl">{t("transactions")}</h4>
              <div>
                <select
                  name="sort"
                  id="sort"
                  onChange={(e) => sortData(e.target.value)}
                >
                  <option value="asc">Newest</option>
                  <option value="desc">Oldest</option>
                </select>
              </div>
            </div>
            <div>
              {data?.map((item, key) => {
                return (
                  <div
                    key={key}
                    className="mb-4 block border-twprimary border-2 rounded-lg p-4"
                  >
                    <div>
                      <h5 className="mb-3">
                        {item?.Provider?._attributes?.Fullname}
                      </h5>
                    </div>
                    <a
                      href={`/product?id=${item?.Provider?._attributes?.CabsId}`}
                      className="font-bold text-xl mb-1 text-twprimary transition"
                    >
                      {item?.Products?.Product?._attributes?.Name}
                    </a>
                    <div className="flex gap-4 items-start mb-1">
                      <div>
                        <p>
                          {t("check_in")}:&nbsp;
                          {moment(
                            item?.Products?.Product?._attributes?.CommencingDate
                          ).format("LL")}
                        </p>
                      </div>
                      <div>
                        <p>
                          {t("check_out")}:&nbsp;
                          {moment(
                            item?.Products?.Product?._attributes?.ConcludingDate
                          ).format("LL")}
                        </p>
                      </div>
                    </div>
                    <div className="flex gap-4 items-start">
                      {item?.Products?.Product?._attributes?.NumberOfAdults &&
                        parseInt(
                          item?.Products?.Product?._attributes?.NumberOfAdults
                        ) > 0 && (
                          <div>
                            <p>
                              {t("adults")}:
                              {
                                item?.Products?.Product?._attributes
                                  ?.NumberOfAdults
                              }
                            </p>
                          </div>
                        )}
                      {item?.Products?.Product?._attributes?.NumberOfChildren &&
                        parseInt(
                          item?.Products?.Product?._attributes?.NumberOfChildren
                        ) > 0 && (
                          <div>
                            <p>
                              {t("children")}:
                              {
                                item?.Products?.Product?._attributes
                                  ?.NumberOfChildren
                              }
                            </p>
                          </div>
                        )}

                      {item?.Products?.Product?._attributes
                        ?.NumberOfConcessions &&
                        parseInt(
                          item?.Products?.Product?._attributes
                            ?.NumberOfConcessions
                        ) > 0 && (
                          <div>
                            <p>
                              {t("seniors")}:
                              {
                                item?.Products?.Product?._attributes
                                  ?.NumberOfConcessions
                              }
                            </p>
                          </div>
                        )}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : loading ? (
        <div>Loading data..</div>
      ) : (
        <div className="flex justify-center items-center">
          <h2 className="text-2xl font-bold">{t("no_transactions")}</h2>
        </div>
      )}
    </main>
  );
};

export default TransactionHistory;
