import React, { useEffect, useState } from "react";
import axios from "axios";
import { Modal } from "react-bootstrap";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import produce from "immer";
import { isEmpty } from "lodash";
import { Carousel } from "react-responsive-carousel";

import {
  bodyRequest,
  headers,
  quoteRequest,
  distributorQuick,
  distributorRequest,
  bodyProvider,
} from "../../helpers/utils";
import { endpoints } from "../../helpers/endpoints";

import Map from "../../components/Maps";
import ProductItems from "./components/ProductItems";
import BasicInfo from "./components/BasicInfo";
import SkeletonDetail from "./components/SkeletonDetail";
import SkeletonItems from "./components/SkeletonItems";
import CheckPrice from "./components/CheckPrice";

import DefaultImg from "../../assets/images/no_image.png";

import "./style.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";

const ProductDetail = () => {
  const [service, setService] = useState();
  const [bookingQuotes, setBookingQuotes] = useState([]);
  const [detailShow, setDetailShow] = useState(false);
  const [productItemShow, setProductItemShow] = useState("none");
  const [skeletonItemShow, setSkeletonItemShow] = useState("none");
  const [quotesInfo, setQuotesInfo] = useState({});
  const [error, setError] = useState(false);
  const [errorItems, setErrorItems] = useState(false);
  const [productId, setProductId] = useState();
  const [totalPrice, setTotalPrice] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [imageSelected, setImageSelected] = useState(0);
  const [providerName, setProviderName] = useState();
  const [pax, setPax] = useState(1);
  const [childPax, setChildPax] = useState(0);
  const [senior, setSenior] = useState(0);

  const [searchParams] = useSearchParams();
  const language = searchParams.get("lang");
  const id = searchParams.get("id");
  const { t } = useTranslation();

  const today = new Date();
  const date = new Date(today);
  date.setDate(date.getDate() + 1);

  const detailRequest = bodyRequest;
  const onReq = searchParams.get("onRequest");

  detailRequest.request.Filter = {
    Type: "Service",
    Ids: "",
  };

  detailRequest.request.Filter.Ids = [id];

  const getProviderName = (service) => {
    axios.post(endpoints.search, bodyProvider).then((res) => {
      res.data.Entities.forEach((element) => {
        element.Children.forEach((child) => {
          if (child.Id === service.Id) {
            setProviderName(element.Name);
          }
        });
      });
    });
  };

  const obxLang = (lang) => {
    if (lang === "jp") {
      return "ja-JP";
    } else if (lang === "cn" || lang === "tw") {
      return "zh-CN";
    } else {
      return "en-US";
    }
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    const langParams = searchParams.get("lang");
    detailRequest.request.Language = obxLang(language);
    quoteRequest.request.Language = obxLang(language);
    bodyProvider.request.Language = obxLang(language);

    if (langParams) {
      detailRequest.request.Language = obxLang(langParams);
      quoteRequest.request.Language = obxLang(langParams);
      bodyProvider.request.Language = obxLang(langParams);
    }

    setDetailShow(false);
    setProductItemShow("none");

    detailRequest.request.Output.Availability.NumberOfDays = 31;

    detailRequest.request.Output.Children = {
      Output: {
        CommonContent: {
          All: true,
        },
        Features: true,
        Rating: true,
        Reviews: {
          IncludeFullDescription: true,
          IncludeShortReview: true,
          MaxReturnCount: 10,
          MaxReturnCountSpecified: true,
        },
        Availability: {
          StartDate: new Date(),
          NumberOfDays: 4,
          MergeMethod: 2,
          FlagCampaign: true,
        },
      },
      Filter: {
        Ids: null,
        Type: 4,
      },
    };
    const product_id = searchParams.get("product_id");

    setProductId(product_id);
    detailRequest.request.ShortName = onReq
      ? distributorRequest
      : distributorQuick;

    if (!langParams || language === langParams) {
      axios
        .post(endpoints.search, detailRequest, { headers: headers })
        .then((response) => {
          if (!isEmpty(response?.data?.Entities)) {
            setService(response.data.Entities[0]);
            getProviderName(response.data.Entities[0]);
            const el = document.querySelector("meta[name='description']");
            el.setAttribute(
              "content",
              response.data.Entities[0].ShortDescription
            );
          }
          if (isEmpty(response?.data?.Entities)) {
            setError(true);
          }

          setDetailShow(true);
        });
    }
  }, [searchParams, location, language]);

  useEffect(() => {
    setBookingQuotes([]);
  }, []);

  useEffect(() => {
    if (service && service.IndustryCategoryGroups[0] === 3) {
      getQuote();
    }
  }, [service]);

  useEffect(() => {
    setBookingQuotes(bookingQuotes.sort((a, b) => a.Name - b.Name));
  }, [bookingQuotes]);

  const getQuote = (values) => {
    setProductItemShow("none");
    quoteRequest.request.Configurations[0].Pax.Adults =
      parseInt(values && values.pax) || 1;
    quoteRequest.request.Configurations[0].Pax.Children =
      parseInt(values && values.children) || 0;
    quoteRequest.request.Configurations[0].Pax.Seniors =
      parseInt(values && values.seniors) || 0;
    quoteRequest.request.CommencementDate =
      (values && new Date(values.date)) || new Date();
    quoteRequest.request.Duration = parseInt(values && values.duration) || null;

    setBookingQuotes([]);

    if (service && service.Children.length > 0) {
      setBookingQuotes([]);
      if (onReq) quoteRequest.request.Shortname = distributorRequest;
      else quoteRequest.request.Shortname = distributorQuick;

      if (productId) {
        let indexId = 0;
        let selectedId = {};
        service.Children.forEach((children, i) => {
          if (children.Code === productId) {
            indexId = i;
            selectedId = children;

            dispatchQuoteBooking(selectedId, indexId);
          }
        });
      } else {
        service.Children.map((children, i) => {
          dispatchQuoteBooking(children, i);
        });
      }
    }
  };

  const dispatchQuoteBooking = (children, i) => {
    quoteRequest.request.IndustryCategoryGroup =
      children.IndustryCategoryGroups[0];
    quoteRequest.request.IndustryCategory = children.IndustryCategory;
    quoteRequest.request.Configurations[0].ProductId = children.Id;
    setSkeletonItemShow("block");

    axios
      .post(endpoints.bookingQuote, quoteRequest, { headers: headers })
      .then((response) => {
        if (response.data.Configurations[0].Quotes) {
          const mergeData = { ...service.Children[i], ...response.data };
          mergeData.id = response.data.Configurations[0].ProductId;
          mergeData.quantity = 1;
          mergeData.type = onReq === "true" ? "req" : "quick";
          mergeData.totalPrice = response.data.Configurations[0].Quotes
            ? response.data.Configurations[0].Quotes[0].TotalPrice
            : null;
          mergeData.price = response.data.Configurations[0].Quotes
            ? response.data.Configurations[0].Quotes[0].TotalPrice
            : null;
          setBookingQuotes((data) => [...data, mergeData]);
        }

        setProductItemShow("block");
        setSkeletonItemShow("none");
      })
      .catch((error) => {
        console.log(error);
        setErrorItems(true);
        setProductItemShow("block");
        setSkeletonItemShow("none");
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let values = null;
    if (service.IndustryCategoryGroups[0] !== 3) {
      values = {
        date: e.target[0] ? e.target[0].value : "",
        duration:
          service.IndustryCategoryGroups[0] === 0
            ? e.target["duration"].value
            : null,
        pax: e.target.pax.value,
        children: service.Settings.PresentChildren
          ? e.target.children.value
          : "",
        seniors: service.Settings.PresentSeniors ? e.target.senior.value : "",
      };
      setQuotesInfo(values);
    }

    getQuote(values);
  };

  const changeQuantity = (value, id) => {
    bookingQuotes.map((item) => {
      if (item.id === id) {
        item.quantity = value;
        const price = value * item.Configurations[0].Quotes[0].TotalPrice;
        if (!isEmpty(totalPrice)) {
          totalPrice.map((item) => {
            if (item.id === id) {
              setTotalPrice(
                produce((draft) => {
                  const dataPrice = draft.find((item) => item.id === id);
                  dataPrice.totalPrice = price;
                })
              );
            } else {
              setTotalPrice((prev) => [...prev, { id: id, totalPrice: price }]);
            }
          });
        } else {
          setTotalPrice((prev) => [...prev, { id: id, totalPrice: price }]);
        }
      }
    });
  };

  const getServiceType = () => {
    let serviceType = null;
    if (service && service.IndustryCategoryGroups) {
      switch (service.IndustryCategoryGroups[0]) {
        case 0:
          serviceType = t("accommodation");
          break;
        case 1:
          serviceType = t("activity");
          break;
        case 2:
          serviceType = t("restaurant");
          break;
        case 3:
          serviceType = t("produce");
          break;
        default:
          return t("accommodation");
      }
    }

    return serviceType;
  };

  const calendarUpdate = (date) => {
    detailRequest.request.Output.Availability.StartDate = date;

    axios
      .post(endpoints.search, detailRequest, { headers: headers })
      .then((response) => {
        setService(response.data.Entities[0]);
        setDetailShow(true);
      });
  };

  const changePax = (count, type) => {
    if (count) {
      let values = {
        date: quotesInfo.date || new Date(),
        duration: quotesInfo.duration,
        pax: quotesInfo.pax || 1,
        children: quotesInfo.children,
        seniors: quotesInfo.seniors,
      };

      if (type === "pax") {
        values.pax = count;
        setPax(count);
      } else if (type === "children") {
        values.children = count;
        setChildPax(count);
      } else {
        values.seniors = count;
        setSenior(count);
      }

      setQuotesInfo(values);
      getQuote(values);
    }
  };

  return (
    <div className="productDetail">
      <div className="container">
        {service ? (
          <p id="breadcrumbs">
            <span>HOME</span>
            <span>
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
            <a
              href={`/?lang=${language}&category=${service?.IndustryCategoryGroups[0]}`}
            >
              {getServiceType()}
            </a>
            <span>
              <FontAwesomeIcon icon={faChevronRight} />
            </span>
            <span>{service?.Name}</span>
          </p>
        ) : (
          <a href="/" className="flex items-center gap-2">
            <FontAwesomeIcon icon={faChevronLeft} />
            Back to Home
          </a>
        )}
        <div className="product">
          <div
            className="skeletonWrapper"
            style={{ display: !detailShow ? "block" : "none" }}
          >
            <SkeletonDetail />
          </div>
          {service && (
            <div
              className="productWrapper"
              style={{ display: detailShow ? "block" : "none" }}
            >
              <h2 className="title">{service.Name}</h2>
              <div className="productHeader">
                <div className="carousel">
                  {service.Images !== null ? (
                    <>
                      <Carousel
                        showArrows={true}
                        dynamicHeight={true}
                        preventMovementUntilSwipeScrollTolerance={true}
                        swipeScrollTolerance={50}
                        showStatus={false}
                        onClickItem={() => setOpenModal(true)}
                        onChange={(i) => setImageSelected(i)}
                      >
                        {service.Images.map((image) => {
                          return (
                            <div key={image.Id}>
                              <img src={image.Url} />
                            </div>
                          );
                        })}
                      </Carousel>
                      <Modal
                        show={openModal}
                        onHide={() => setOpenModal(false)}
                        centered
                        size="lg"
                      >
                        <Carousel
                          showArrows={true}
                          dynamicHeight={true}
                          preventMovementUntilSwipeScrollTolerance={true}
                          swipeScrollTolerance={50}
                          showStatus={false}
                          selectedItem={imageSelected}
                        >
                          {service.Images.map((image) => {
                            return (
                              <div key={image.Id}>
                                <img src={image.Url} />
                              </div>
                            );
                          })}
                        </Carousel>
                      </Modal>
                    </>
                  ) : (
                    <img src={DefaultImg} />
                  )}
                </div>
              </div>
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: service.LongDescription }}
              ></div>
              {service?.IndustryCategoryGroups[0] !== 3 && (
                <CheckPrice
                  date={date}
                  service={service}
                  handleSubmit={handleSubmit}
                  calendarUpdate={calendarUpdate}
                  pax={pax}
                  childPax={childPax}
                  senior={senior}
                  setPax={setPax}
                  setChildPax={setChildPax}
                  setSenior={setSenior}
                />
              )}
              <div
                className="availableProducts mb-4"
                style={{ display: productItemShow }}
              >
                <div className="sectionTitle">
                  <span>
                    {service?.IndustryCategoryGroups &&
                    service.IndustryCategoryGroups[0] === 1
                      ? t("available_products_activity")
                      : service.IndustryCategoryGroups[0] === 3
                      ? t("available_products_goods")
                      : t("available_products")}
                  </span>
                </div>
                <ProductItems
                  bookingQuotes={bookingQuotes}
                  changeQuantity={changeQuantity}
                  language={language}
                  service={service}
                  quotesInfo={quotesInfo}
                  error={errorItems}
                  totalPrice={totalPrice}
                  onRequest={onReq}
                  changePax={changePax}
                />
              </div>
              <SkeletonItems skeletonItemShow={skeletonItemShow} />
              <div className="grid lg:grid-cols-2 gap-10 mt-10">
                <BasicInfo service={service} providerName={providerName} />
                {service && service.Geocodes !== null && (
                  <div className="map">
                    <div className="mapContainer">
                      <Map positions={service} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {error && (
            <div className="text-center flex justify-center items-center text-2xl font-bold min-h-[20vh]">
              {t("not_available")}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
